import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import algoliasearch from "algoliasearch/lite"
import Link from "gatsby-link"
import React from "react"
import { connectHits, InstantSearch, SearchBox } from "react-instantsearch-dom"
import styled from "styled-components"
import Layout from "../components/layout"
import { Line } from "../components/styles/styles"

const searchClient = algoliasearch(
  "NIMCYHWW8Y",
  "57a9af80714b4a26d851cfb38b180cc5"
)

const Hits = ({ hits }) => {
  return (
    <Grid style={{ marginLeft: "40px" }} container>
      {hits.map(hit => (
        <Grid
          key={hit.objectID}
          item
          lg={3}
          xs={12}
          sm={4}
          style={{ margin: "10px", marginTop: "10px" }}
        >
          <Link to={hit.fields.slug}>
            <CardStyled
              image={hit.frontmatter.featuredImage?.childImageSharp.fixed.src}
              // className={classes.card}
            >
              <CardContent>
                <Title>{hit.frontmatter.title}</Title>
              </CardContent>
              <Line />
              <CardActions>
                <ButtonStyled aria-label="More" size="small" color="primary">
                  Read More
                </ButtonStyled>
              </CardActions>
            </CardStyled>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
const CustomHits = connectHits(Hits)

const SearchPage = () => {
  return (
    <Layout>
      {/* TODO:add margin bottom to prevent overflow on smaller screens */}
      <div style={{ marginLeft: "40px", marginTop: "40px" }}>
        <InstantSearch searchClient={searchClient} indexName="ngo">
          <SearchBox />
          <div style={{ display: "flex" }}>
            <CustomHits />
          </div>
        </InstantSearch>
      </div>
    </Layout>
  )
}
export default SearchPage

const Title = styled.p`
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #000;
  // color: #fff;
  background: #fff;
  padding: 0px 10px 0px 10px;
`
const CardStyled = styled(Card)`
  box-shadow: none;
  margin: 5%;
  border: 1px solid #d4d4d4ba;
  background: url(${props => props.image}) no-repeat center;
`
const ButtonStyled = styled(Button)`
  font-family: "GT-Walsheim-Regular", sans-serif;
  color: #fff;
  background: black;
  font-weight: 700;
  font-size: 12px;
`
